import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import config from 'config';

import { getDefaultLocale } from 'utils/i18n.utils';

import { AtomKeys } from 'store';

export const localeStore = createJSONStorage<string>(() => localStorage);

const localeStorageAtom = atomWithStorage(
  AtomKeys.Locale,
  getDefaultLocale(),
  localeStore,
);

// Ensures we only get back a valid locale matching our tenant config
export const localeAtom = atom<string, string>(
  (get) => {
    const locale = get(localeStorageAtom);
    return config.LOCALES.includes(locale) ? locale : config.DEFAULT_LOCALE;
  },
  (_, set, arg) => {
    config.LOCALES.includes(arg)
      ? set(localeStorageAtom, arg)
      : set(localeStorageAtom, config.DEFAULT_LOCALE);
  },
);
