import { isDev } from 'utils/env.utils';

import { Headers } from 'services/api/axios/headers';

export const getReCaptchaHeaders = (token: string): Record<string, string> =>
  isDev()
    ? {
        [Headers.RecaptchaTokenMock]: '',
      }
    : {
        [Headers.RecaptchaToken]: token,
      };
