import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { TestSession } from 'types/test-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: TestSession['id'];
}

const fetchSession = async (params: Variables) => {
  const { posName, sessionId } = params;
  const { data } = await apiService.fetchTestSession(posName, sessionId);
  return data;
};

export const useTestSession = (
  params: Variables,
  config: UseCommonQueryOptions<TestSession> = {},
) => {
  const { data, status, isLoading, isFetching } = useQuery(
    QueryKeys.testSessions.byId(params.posName, params.sessionId),
    () => fetchSession(params),
    config,
  );

  return {
    data,
    status,
    isLoading,
    isFetching,
  };
};
