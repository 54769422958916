import { Components, darken } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    disableFocusRipple: true,
    variant: 'contained',
  },
  styleOverrides: {
    contained: {
      borderRadius: 20,
      fontWeight: 'bold',
      lineHeight: 1.333333,
      fontSize: '0.75rem',
      padding: '13px 16px 12px',
    },
    containedPrimary: {
      backgroundColor: palette.primary.main,
      color: palette.neutral01[100],

      '&:hover:not(.Mui-disabled)': {
        backgroundColor: palette.primary.main,
        opacity: '80%',
      },
      '&:active': {
        opacity: 1,
        backgroundColor: darken(palette.primary.main, 0.1),
      },
    },
    containedSecondary: {
      backgroundColor: palette.neutral01[100],
      color: palette.neutral01[500],
      border: '1px solid #E8E4E4',

      '&:hover': {
        backgroundColor: palette.neutral01[100],
      },
    },
  },
};
