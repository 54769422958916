import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTestSession, useUpdateTestSessionFeedback } from 'queries';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';
import {
  TestErrorContext,
  TestSession,
  TestSessionFeedback,
  TestSessionStatus,
} from 'types/test-session.types';

import { Routes } from 'pages/routes.constants';

interface Props {
  posName: Pos['name'];
  testId: string;
  sessionId: TestSession['id'];
  onChange?(session: TestSession): void;
}

const TestErrorContextMapping: { [key: string]: TestErrorContext } = {
  [TestSessionStatus.FAILED_TIMEOUT]: TestErrorContext.TIMEOUT,
  [TestSessionStatus.FAILED_MACHINE_NO_SALE]: TestErrorContext.MACHINE_ERROR,
  [TestSessionStatus.FAILED_MACHINE_ERROR]: TestErrorContext.MACHINE_ERROR,
  [TestSessionStatus.FAILED_VENDING_MACHINE_OFFLINE]:
    TestErrorContext.MACHINE_ERROR,
  [TestSessionStatus.FAILED]: TestErrorContext.SESSION_ERROR,
  [TestSessionStatus.CANCELLED]: TestErrorContext.SESSION_ERROR,
};

const useValidateTestSession = (posName: Pos['name'], testId: string) => {
  const history = useHistory();
  const { updateTestSessionFeedback } = useUpdateTestSessionFeedback();
  const validate = useCallback(
    (session?: TestSession) => {
      if (!session) return;

      const context = TestErrorContextMapping[session.status];
      if (context) {
        // A timeout should not update the test session since its undetermined
        if (context !== TestErrorContext.TIMEOUT) {
          updateTestSessionFeedback(
            {
              posName,
              sessionId: session.id,
              feedback: TestSessionFeedback.FAILURE,
            },
            {
              // Whatever happend with posting the feedback just redirect to test failure
              onSettled: () => {
                history.push(Routes.MachineTestFailure, {
                  context,
                  posName,
                  testId,
                });
              },
            },
          );
        } else {
          history.push(Routes.MachineTestFailure, { context, posName, testId });
        }
      }
    },
    [history, posName, testId, updateTestSessionFeedback],
  );

  return validate;
};

export const usePollTestSession = ({
  posName,
  testId,
  sessionId,
  onChange,
}: Props) => {
  const validateSession = useValidateTestSession(posName, testId);
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(
    1000,
  );

  const handleSuccess = useCallback(
    (session: TestSession) => {
      validateSession(session);
      onChange?.(session);
    },
    [onChange, validateSession],
  );

  const { data } = useTestSession(
    { posName: posName, sessionId: sessionId },
    {
      enabled: !!sessionId.length,
      refetchInterval,
      onSuccess: handleSuccess,
      onError: (error) => {
        // ignore error if it's due to timeout as it gives a lot of false positives (users returning to an old session tab)
        if (error.code !== 'ECONNABORTED') {
          Sentry.addBreadcrumb({
            data: {
              [SentryTags.FLOW]: 'TEST',
              [SentryTags.POS_ID]: posName,
              [SentryTags.SESSION_ID]: sessionId,
              [SentryTags.FUNCTION_NAME]: 'usePollTestSession',
              exception: error,
            },
            level: 'warning',
          });
        }
      },
    },
  );

  const handleStopPolling = useCallback(
    () => setRefetchInterval(undefined),
    [],
  );

  return {
    testSession: data,
    stopPolling: handleStopPolling,
  };
};
