import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiToggleButtonGroup: Components['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      borderRadius: 'none',
    },
    grouped: {
      color: palette.neutral01[500],

      '&:not(:last-child)': {
        marginBottom: '16px',
      },
      '&:not(:last-of-type)': {
        border: `1px solid ${palette.neutral01[400]}`,
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
      },
      '&:not(:first-of-type)': {
        border: `1px solid ${palette.neutral01[400]}`,
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
      },
    },
  },
};
