import { AxiosResponse } from 'axios';

import { toBase64 } from 'utils/encoding.utils';

import { Pos } from 'types/pos.types';
import {
  TestSession,
  TestSessionFeedback,
  TestSessionParams,
} from 'types/test-session.types';

import RestApiService from '.';
import { getReCaptchaHeaders } from './utils';

export default {
  createTestSession(
    this: RestApiService,
    posName: Pos['name'],
    params: TestSessionParams,
  ) {
    return this.api.post<TestSessionParams, AxiosResponse<TestSession>>(
      `/pos/${toBase64(posName)}/test-session`,
      params,
    );
  },
  fetchTestSession(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: TestSession['id'],
  ) {
    return this.api.get<TestSession>(
      `/pos/${toBase64(posName)}/test-session/${sessionId}`,
    );
  },
  updateTestSessionFeeback(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: TestSession['id'],
    testFeedback: TestSessionFeedback,
    recaptchaToken: string,
  ) {
    return this.api.patch<
      { testFeedback: TestSessionFeedback },
      AxiosResponse<TestSession>
    >(
      `/pos/${toBase64(posName)}/test-session/${sessionId}`,
      {
        testFeedback,
      },
      {
        headers: getReCaptchaHeaders(recaptchaToken),
      },
    );
  },
};
