import { createTheme } from '@mui/material/styles';

import { font, palette, variants } from './branding';
import * as components from './overrides';

// Setup palette
let theme = createTheme({
  typography: {
    fontFamily: font,
    ...variants,
  },
  shape: {
    borderRadius: 5,
  },
});

// Setup breakpoints
theme = createTheme(theme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 767,
      lg: 1200, // max width of the app
    },
  },
});

theme = createTheme(theme, { components, palette });

export * from './types';
export default theme;
