import { Components } from '@mui/material';

export const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      backgroundColor: '#1A1919',
      padding: '12px 18px',
    },
    arrow: {
      color: '#1A1919',
    },
  },
};
