import { AxiosResponse } from 'axios';

import { toBase64 } from 'utils/encoding.utils';

import { ApiResponse } from 'types/api.types';
import { PaymentMethod, Pos } from 'types/pos.types';
import { VendSession, VendSessionProduct } from 'types/vend-session.types';

import RestApiService from '.';

export default {
  createVendSession(
    this: RestApiService,
    posName: Pos['name'],
    pspReturnToken?: string,
  ) {
    return this.api.post<never, AxiosResponse<VendSession>>(
      `/pos/${toBase64(posName)}/vend-session`,
      { pspReturnToken, doNotTransform: true },
    );
  },
  fetchVendSession(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: VendSession['id'],
  ) {
    return this.api.get<VendSession>(
      `/pos/${toBase64(posName)}/vend-session/${sessionId}`,
    );
  },
  closeVendSession(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: VendSession['id'],
  ) {
    return this.api.post<never>(
      `/pos/${toBase64(posName)}/vend-session/${sessionId}/close`,
    );
  },
  createVendSessionProducts(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: VendSession['id'],
    params: VendSessionProduct[],
  ) {
    return this.api.post<ApiResponse<VendSessionProduct>>(
      `/pos/${toBase64(posName)}/vend-session/${sessionId}/products`,
      {
        data: params,
      },
    );
  },
  createVendSessionPaymentMethod(
    this: RestApiService,
    posName: Pos['name'],
    sessionId: VendSession['id'],
    paymentMethod: PaymentMethod,
  ) {
    return this.api.post<
      { paymentMethod: PaymentMethod },
      AxiosResponse<VendSession>
    >(`/pos/${toBase64(posName)}/vend-session/${sessionId}/payment-method`, {
      paymentMethod,
    });
  },
};
