import {
  Components,
  stepConnectorClasses,
  stepLabelClasses,
} from '@mui/material';

import { palette } from 'theme/branding';

const { line, completed, active } = stepConnectorClasses;
const { alternativeLabel: alternativeStepLabel } = stepLabelClasses;

export const MuiStep: Components['MuiStep'] = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export const MuiStepConnector: Components['MuiStepConnector'] = {
  styleOverrides: {
    root: {
      '&.Mui-active': {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: palette.primary.main,
        },
      },
    },
    line: {
      borderTopWidth: 1,
    },
    alternativeLabel: {
      [`&.${active} .${line}, &.${completed} .${line}`]: {
        borderColor: palette.primary.main,
      },
    },
  },
};

export const MuiStepLabel: Components['MuiStepLabel'] = {
  styleOverrides: {
    root: {
      '&.Mui-active': {
        color: palette.primary.main,
      },
      '&.Mui-completed': {
        color: palette.primary.main,
      },
    },
    labelContainer: {
      width: 'auto',
    },
    label: {
      fontWeight: 700,
      fontSize: 11,
      color: palette.neutral01[500],
      [`&.${completed}, &.${active}`]: {
        color: palette.primary.main,
        fontWeight: 700,
      },
      [`&.${alternativeStepLabel}`]: {
        marginTop: 8,
      },
    },
  },
};
