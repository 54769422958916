import { styled } from '@mui/material/styles';

export const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  width: 24,
  height: 24,
  zIndex: 1,
  fontWeight: 700,
  color: theme.palette.neutral01[100],
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor:
    ownerState.active || ownerState.completed
      ? theme.palette.primary.main
      : theme.palette.neutral01[500],
}));
