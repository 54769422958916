import * as Sentry from '@sentry/react';
import { FC } from 'react';

import { ErrorContext } from 'types/error.types';

import ErrorScreen from 'components/@errors/ErrorScreen';

const RouteBoundary: FC = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'pages')}
    fallback={() => <ErrorScreen context={ErrorContext.GENERAL} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RouteBoundary;
