import { useMemo } from 'react';

import { useFeedbackReasons } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { ApiResponse } from 'types/api.types';
import { FeedbackContext, FeedbackReason } from 'types/feedback.types';

export const useFeedbackReasonsByContext = (
  context: FeedbackContext,
  config: UseCommonQueryOptions<ApiResponse<FeedbackReason[]>> = {},
) => {
  const { data, isLoading, status } = useFeedbackReasons(config);

  const topReasons = useMemo(() => {
    const reasonsForContext = data.filter(
      (reason) =>
        reason.applicableSteps && reason.applicableSteps.includes(context),
    );
    return reasonsForContext.slice(0, 3);
  }, [data, context]);

  return {
    topReasons,
    isLoading,
    status,
  };
};
