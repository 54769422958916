import { Components, SvgIcon } from '@mui/material';

import { palette } from 'theme/branding';

import { IcoWarning } from 'components/@icons';

export const MuiAlert: Components['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <SvgIcon component={IcoWarning} fontSize="small" />,
    },
  },
  styleOverrides: {
    standard: {
      padding: '6px 12px',
    },
    standardError: {
      backgroundColor: palette.alert01,
      color: '#1A1919',
    },
  },
};
