import * as Sentry from '@sentry/react';
import { AxiosInstance } from 'axios';

import config from 'config';

import { getClientId } from 'services/api/utils';
import { AtomKeys, localeStore } from 'store';

import { Headers } from '../headers';

export const createRequestHeaderInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (request) => {
      let locale: string = config.DEFAULT_LOCALE;
      try {
        locale = localeStore.getItem(AtomKeys.Locale) ?? config.DEFAULT_LOCALE;
      } catch (e) {}

      const clientId = getClientId();

      if (request.headers) {
        request.headers[Headers.AcceptLanguage] = locale;
        request.headers[Headers.Tenant] = config.TENANT_ID;
        request.headers[Headers.Client] = clientId;
      }

      return request;
    },
    (error) => {
      Sentry.addBreadcrumb({
        message: 'API request error',
        data: {
          error,
        },
      });
      Promise.reject(error);
    },
  );
};
