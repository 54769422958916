import { Pos } from 'types/pos.types';
import { TestSession } from 'types/test-session.types';
import { VendSession } from 'types/vend-session.types';

// Use this enum to eliminate typos
enum Keys {
  User = 'user',
  Pos = 'pos',
  VendSession = 'vendSession',
  TestSession = 'testSession',
  Products = 'products',
  Feedback = 'feedback',
  Reasons = 'reasons',
}

export const QueryKeys = {
  pos: {
    byName: (posName: Pos['name']) => [Keys.Pos, posName],
    products: (posName: Pos['name']) => [Keys.Pos, posName, Keys.Products],
  },
  vendSessions: {
    byId: (posName: Pos['name'], sessionId: VendSession['id']) => [
      Keys.Pos,
      posName,
      Keys.VendSession,
      sessionId,
    ],
  },
  feedback: {
    reasons: (locale: string) => [Keys.Feedback, Keys.Reasons, locale],
  },
  testSessions: {
    byId: (posName: Pos['name'], sessionId: TestSession['id']) => [
      Keys.Pos,
      posName,
      Keys.TestSession,
      sessionId,
    ],
  },
};
