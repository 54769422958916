import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useCloseVendSession } from 'queries';
import { ErrorContext } from 'types/error.types';
import { FeedbackContext } from 'types/feedback.types';
import { Pos } from 'types/pos.types';
import { VendSession } from 'types/vend-session.types';

import { Routes } from 'pages/routes.constants';

import { Footer, StepIndicator } from 'components/@common';
import { FeedBack } from 'components/@dialogs';
import { Content, Header, Step, Title } from 'components/@step-flow';

import errorScreenLookup, { ErrorScreenErrors } from './config';

const CLOSE_SESSION_ERROR_CONTEXT = [
  ErrorContext.GENERAL,
  ErrorContext.PURCHASE_ERROR,
  ErrorContext.TIMEOUT,
  ErrorContext.CANCELLED,
  ErrorContext.SUPPORT,
];
interface Props {
  context: ErrorScreenErrors;
  posName?: Pos['name'];
  sessionId?: VendSession['id'];
  feedback?: FeedbackContext;
}

const ErrorScreen = ({
  context,
  posName,
  sessionId,
  feedback: overwriteFeedback,
}: Props) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { Illustration, title, feedback } = errorScreenLookup[context];
  const feedbackContext = overwriteFeedback ?? feedback;
  const { closeSession } = useCloseVendSession();

  // Close the session if needed
  useEffect(() => {
    if (
      posName &&
      sessionId &&
      CLOSE_SESSION_ERROR_CONTEXT.includes(context as ErrorContext)
    ) {
      closeSession({ posName, sessionId });
    }
  }, [closeSession, context, posName, sessionId]);

  // Can this be reused or maybe configured?
  useEffect(() => {
    const id = setTimeout(() => setFeedbackOpen(true), 1500);
    return () => clearTimeout(id);
  }, []);

  return (
    <>
      <Step>
        <Header>
          <StepIndicator activeStep={3} type="payment_flow" />
        </Header>
        <Title>
          <FormattedMessage id={title} />
        </Title>
        <Content>
          <Stack alignItems="center" spacing={4}>
            <img
              alt={title}
              style={{ width: '25vh', height: 'auto' }}
              src={Illustration}
            />
            <Button
              aria-label="try again"
              fullWidth
              component={Link}
              to={Routes.Root}
            >
              <FormattedMessage id="label.try_again" />
            </Button>
          </Stack>
          <Footer />
        </Content>
      </Step>
      {feedbackContext && sessionId && (
        <FeedBack
          sessionId={sessionId}
          open={feedbackOpen}
          context={feedbackContext}
          onClose={() => setFeedbackOpen(false)}
        />
      )}
    </>
  );
};

export default ErrorScreen;
