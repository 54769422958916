import { useMutation } from 'react-query';

import { apiService } from 'services';
import { PaymentMethod, Pos } from 'types/pos.types';
import { VendSession } from 'types/vend-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: VendSession['id'];
  paymentMethod: PaymentMethod;
}

const createPaymentMethod = async (variables: Variables) => {
  const { posName, sessionId, paymentMethod } = variables;
  const { data } = await apiService.createVendSessionPaymentMethod(
    posName,
    sessionId,
    paymentMethod,
  );

  return data;
};

export const useCreateVendSessionPaymentMethod = () => {
  const { mutateAsync, isLoading } = useMutation(createPaymentMethod);

  return {
    createPaymentMethod: mutateAsync,
    isLoading,
  };
};
