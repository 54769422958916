import { Components, darken } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        backgroundColor: palette.neutral01['400'],
      },
    },
    colorPrimary: {
      backgroundColor: palette.primary.main,
      color: palette.neutral01[100],
      padding: '2px',

      '&:hover': {
        backgroundColor: palette.primary.main,
        opacity: '80%',
      },
      '&:active': {
        opacity: 1,
        backgroundColor: darken(palette.primary.main, 0.1),
      },

      '&.Mui-disabled': {
        backgroundColor: palette.neutral01['400'],
      },
    },
  },
};
