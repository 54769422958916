import { Box, LinearProgress, Typography } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useTimer, useVibration } from 'hooks';
import { useNotifications } from 'services/notifications';

import OverlayAnimation from './OverlayAnimation';

const SHOW_SNACKBAR_DELAY = 14; // in seconds
const SHOW_OVERLAY_DELAY = 15; // in seconds

interface Props {
  /** Timestamp of vendSession startTime in seconds */
  startTime: number;
  timeout: number;
  onComplete?(): void;
}

/**
 * When a user refreshes accidently, we need to calculate the remaining timeout of the vend session currently in progress.
 *
 * @param startTime start time of a vend session in seconds
 * @param timeout timeout in seconds
 * @returns remaining time in seconds
 */
function getRemainingTimeout(startTime: number, timeout: number) {
  const now = new Date();
  const nowInSeconds = now.getTime() / 1000;
  const _timeout = startTime + timeout - nowInSeconds;

  return Math.round(_timeout);
}

const CountDownTimer = ({ timeout, startTime, onComplete }: Props) => {
  const intl = useIntl();
  const notifications = useNotifications();
  const [hasNotified, setHasNotified] = useState(false);
  const { remainingTime } = useTimer({
    timeout: getRemainingTimeout(startTime, timeout),
    onComplete,
  });

  const { minutes, seconds } = intervalToDuration({
    start: 0,
    end: remainingTime * 1000,
  });

  const showOverlay = remainingTime === SHOW_OVERLAY_DELAY && !hasNotified;
  const showSnackbar = remainingTime === SHOW_SNACKBAR_DELAY && !hasNotified;

  useVibration({ enabled: showOverlay });

  useEffect(() => {
    if (showSnackbar) {
      notifications.error(
        intl.formatMessage({
          id: 'notification.select_drink.warn_to_start_selection',
        }),
      );
      setHasNotified(true);
    }
  }, [intl, notifications, showSnackbar]);

  return (
    <Box textAlign="center">
      {showOverlay && <OverlayAnimation />}
      <LinearProgress
        variant="determinate"
        color="primary"
        value={(remainingTime * 100) / timeout}
      />

      <Typography
        variant="paragraph01"
        color="neutral01.100"
        fontWeight="bold"
        sx={{ mt: '4px' }}
      >
        {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </Typography>
    </Box>
  );
};

export default CountDownTimer;
