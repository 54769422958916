import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';
import { TestSessionParams } from 'types/test-session.types';

interface Variables {
  posName: Pos['name'];
  testId: string;
  code: string;
}

const createTestSession = async (
  posName: Pos['name'],
  params: TestSessionParams,
) => {
  const { data } = await apiService.createTestSession(posName, params);
  return data;
};

export const useCreateTestSession = () => {
  const { mutate, isLoading } = useMutation(
    async ({ posName, testId, code }: Variables) => {
      return createTestSession(posName, { testId, code });
    },
    {
      onError: (e, { posName, testId }) => {
        Sentry.addBreadcrumb({
          data: {
            [SentryTags.FLOW]: 'TEST',

            [SentryTags.POS_ID]: posName,
            [SentryTags.TEST_ID]: testId,
            [SentryTags.FUNCTION_NAME]: 'useCreateTestSession',
            exception: e,
          },
          level: 'warning',
        });
      },
    },
  );

  return {
    createTestSession: mutate,
    isLoading,
  };
};
