export enum Tenants {
  BE = 'BE',
  DE = 'DE',
  NO = 'NO',
}

export const TENANT_CONFIGS = {
  [Tenants.BE]: {
    TENANT_ID: Tenants.BE,
    CURRENCY: '€',
    CURRENCY_ISO: 'EUR',
    TITLE: 'Paydrink.be',
    DESCRIPTION: 'Paydrink by Coca-Cola European Partners',
    API_BASE_DEVELOPMENT: 'https://staging.paydrink.be/api', // only used for local development
    LOCALES: ['nl', 'fr', 'en'],
    DEFAULT_LOCALE: 'nl',
    DEFAULT_LOCALE_DATE: 'nl',
    COOKIES_BANNER_DOMAIN_SCRIPT_ID: '17902d55-c0ed-42ed-9539-06fcb36730e3',
  },
  [Tenants.DE]: {
    TENANT_ID: Tenants.DE,
    CURRENCY: '€',
    CURRENCY_ISO: 'EUR',
    TITLE: 'Cokepay.de',
    DESCRIPTION: 'Cokepay by Coca-Cola European Partners',
    API_BASE_DEVELOPMENT: 'https://staging.cokepay.de/api', // only used for local development
    LOCALES: ['de', 'en'],
    DEFAULT_LOCALE: 'de',
    DEFAULT_LOCALE_DATE: 'de',
    COOKIES_BANNER_DOMAIN_SCRIPT_ID: 'a273f13a-05c8-42e7-82f3-94c3ac094180',
  },
  [Tenants.NO]: {
    TENANT_ID: Tenants.NO,
    CURRENCY: 'kr',
    CURRENCY_ISO: 'NOK',
    TITLE: 'Coca-Colaautomaten.no',
    DESCRIPTION: 'Coca-Colaautomaten by Coca-Cola European Partners',
    API_BASE_DEVELOPMENT: 'https://staging.coca-colaautomaten.no/api',
    LOCALES: ['no', 'en'],
    DEFAULT_LOCALE: 'no',
    DEFAULT_LOCALE_DATE: 'no',
    COOKIES_BANNER_DOMAIN_SCRIPT_ID: '2b9cbdf0-fb7d-47a8-85f1-3adf0a9c219d',
  },
};
