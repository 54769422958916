import { Box, BoxProps, Typography } from '@mui/material';

type Props = BoxProps;

const Title = ({ children, ...boxProps }: Props) => {
  return (
    <Box sx={{ mx: 3, my: 2, textAlign: 'center', ...boxProps }}>
      <Typography variant="heading05" fontWeight="bold" color="neutral01.100">
        {children}
      </Typography>
    </Box>
  );
};

export default Title;
