import IllustrationQrInvalid from 'assets/svg/ico-invalid-qr-code.svg';
import IllustrationGeneralerror from 'assets/svg/illustration_generalerror.svg';
import IllustrationMachineoffline from 'assets/svg/illustration_machineoffline.svg';
import IllustrationPaymenterror from 'assets/svg/illustration_paymenterror.svg';

import { ErrorContext } from 'types/error.types';
import { FeedbackContext } from 'types/feedback.types';

type ErrorScreenConfig = {
  [key: string]: {
    Illustration: string;
    title: string;
    feedback?: FeedbackContext;
  };
};

const config: ErrorScreenConfig = {
  [ErrorContext.NOT_SUPPORTED]: {
    Illustration: IllustrationGeneralerror,
    title: 'error.page.not_supported.title',
  },
  [ErrorContext.MACHINE_ERROR]: {
    Illustration: IllustrationMachineoffline,
    title: 'page.error.machine_error.title',
  },
  [ErrorContext.MACHINE_OFFLINE]: {
    Illustration: IllustrationMachineoffline,
    title: 'page.error.machine_offline.title',
  },
  [ErrorContext.SESSION_IN_PROGRESS]: {
    Illustration: IllustrationGeneralerror,
    title: 'page.error.session_in_progress.title',
  },
  [ErrorContext.CANCELLED]: {
    Illustration: IllustrationGeneralerror,
    title: 'page.error.cancelled.title',
  },
  [ErrorContext.AUTOMATIC_REFUND]: {
    Illustration: IllustrationMachineoffline,
    title: 'automatic_refund.title',
  },
  // Pages with feedback dialog
  [ErrorContext.TIMEOUT]: {
    Illustration: IllustrationGeneralerror,
    title: 'page.error.connection_lost.title',
    feedback: FeedbackContext.TIMEOUT,
  },
  [ErrorContext.PAYMENT_FAILED]: {
    Illustration: IllustrationPaymenterror,
    title: 'page.error.payment_failed.title',
    feedback: FeedbackContext.PAYMENT_FAILED,
  },
  [ErrorContext.SUPPORT]: {
    Illustration: IllustrationGeneralerror,
    title: 'complain_form.error_screen.title',
    feedback: FeedbackContext.SUPPORT,
  },
  [ErrorContext.INVALID_QR]: {
    Illustration: IllustrationQrInvalid,
    title: 'payment_flow.step_1.error_view.title',
    feedback: FeedbackContext.INVALID_QR,
  },
  [ErrorContext.NO_PAYMENT_METHOD_CONFIGURED]: {
    Illustration: IllustrationGeneralerror,
    title: 'page.error.no_payment_method_configured.title',
    feedback: FeedbackContext.NO_PAYMENT_METHOD_CONFIGURED,
  },
  // Special case, general is catch all and passes its feedback through the component props
  [ErrorContext.GENERAL]: {
    Illustration: IllustrationGeneralerror,
    title: 'page.error.something_went_wrong.title',
  },
};

export type ErrorScreenErrors = keyof typeof config;

export default config;
