import * as Sentry from '@sentry/react';
import { FC } from 'react';

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary: FC = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'root')}
    fallback={() => <h1>An error occured</h1>}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
