import { useEffect, useRef, useState } from 'react';

interface Props {
  timeout?: number;
  onComplete?(): void;
}

export const useTimer = ({ timeout, onComplete }: Props) => {
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  const [remainingTime, setRemainingTime] = useState(timeout || 0);

  // handles counting down every second
  useEffect(() => {
    intervalRef.current = setInterval(
      () => setRemainingTime((existing) => existing - 1),
      1000,
    );
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  // handles oncomplete being called and timer being stopped
  useEffect(() => {
    if (remainingTime <= 0) {
      onComplete?.();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [onComplete, remainingTime]);

  // Handles syncing timeout with remainingTime
  useEffect(() => {
    if (timeout) {
      setRemainingTime(timeout);
    }
  }, [timeout]);

  return {
    remainingTime,
  };
};
