import { AxiosInstance } from 'axios';

import feedbackApi from './feedback.api';
import posApi from './pos.api';
import supportApi from './support.api';
import testSessionApi from './test-session.api';
import vendSessionApi from './vend-session.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }
  // SUPPORT API
  public createSupport = supportApi.createSupport;

  // FEEDBACK API
  public createFeedback = feedbackApi.createFeedback;
  public fetchFeedbackReasons = feedbackApi.fetchFeedbackReasons;

  // POS API
  public fetchPos = posApi.fetchPos;
  public fetchPosProducts = posApi.fetchPosProducts;
  public unlockPos = posApi.unlockPos;

  // VEND SESSION API
  public createVendSession = vendSessionApi.createVendSession;
  public fetchVendSession = vendSessionApi.fetchVendSession;
  public closeVendSession = vendSessionApi.closeVendSession;
  public createVendSessionProducts = vendSessionApi.createVendSessionProducts;
  public createVendSessionPaymentMethod =
    vendSessionApi.createVendSessionPaymentMethod;

  // TEST SESSION API
  public createTestSession = testSessionApi.createTestSession;
  public fetchTestSession = testSessionApi.fetchTestSession;
  public updateTestSessionFeeback = testSessionApi.updateTestSessionFeeback;
}

export default RestApiService;
