import humps from 'humps';

const ignoreUppercaseFields = (key: string, convert: (key: string) => string) =>
  /^[A-Z]+$/.test(key) ? key : convert(key);

export const transformRequest = (data: Record<string, unknown>) => {
  if (data instanceof FormData) return data;

  if (typeof data === 'object') {
    if (data.doNotTransform) {
      delete data.doNotTransform;

      return JSON.stringify(data);
    }
    // convert all body keys to snake_case
    return JSON.stringify(humps.decamelizeKeys(data));
  } else {
    return humps.decamelizeKeys(data);
  }
};

export const transformResponse = (data: string) => {
  if (data) {
    try {
      // convert all body keys to camelCase
      return humps.camelizeKeys(JSON.parse(data), ignoreUppercaseFields);
    } catch (error) {
      // We couldn't parse the response from JSON
      return data;
    }
  }

  return null;
};
