import { Components } from '@mui/material';

export const MuiDrawer: Components['MuiDrawer'] = {
  styleOverrides: {
    paper: {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      padding: 16,
    },
  },
};
