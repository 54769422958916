import { Step, StepLabel, Stepper } from '@mui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { StepIconRoot } from './StepIndicator.styles';

interface StepIndicatorProps {
  activeStep: number;
  type: 'payment_flow' | 'machine_test_flow';
}

interface CustomStepIconProps {
  completed: boolean;
  active: boolean;
  index: number;
}

const CustomStepIcon = ({ completed, active, index }: CustomStepIconProps) => (
  <StepIconRoot ownerState={{ completed, active }}>{index + 1}</StepIconRoot>
);

const StepIndicator = ({ activeStep, type }: StepIndicatorProps) => {
  const intl = useIntl();
  const steps = useMemo(
    () => [
      `${type}.step_1.navigation.label`,
      `${type}.step_2.navigation.label`,
      `${type}.step_3.navigation.label`,
    ],
    [type],
  );

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => (
              <CustomStepIcon {...props} index={index} />
            )}
          >
            {intl.formatMessage({ id: label })}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
