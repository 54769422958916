// @flow

/*
 *
 * Mandrill config
 * Use these dictionaries to configure and register your services to Mandrill's service manager
 *
 */

/*
 *
 * Service config dictionaries
 *
 */

// provider: googleanalytics
// service id: googleanalytics1
// service name: Google Analytics

export const googleanalytics1 = {
  id: 'googleanalytics_1',
  accountIdProd: '',
  accountIdTest: 'UA-134860315-1',
  dispatchInterval: 30,
};
