import { useCallback, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { StepInfoProps, StepType } from './types';

interface Props<Values extends {}> {
  header?(props: StepInfoProps<Values>): JSX.Element;
  footer?(props: StepInfoProps<Values>): JSX.Element;
  steps: ((props: StepInfoProps<Values>) => JSX.Element)[];
  initialStep?: StepType;
  initialValues?: Values;
  initialStepsDisabled?: StepType[];
  onSubmit?: (values: Values) => void;
  onStepChange?(currentStep: StepType): void;
}

const MultiStep = <TValues extends {}>({
  header,
  footer,
  steps,
  initialStep = 1,
  initialValues = {} as TValues,
  initialStepsDisabled = [],
  onSubmit,
  onStepChange,
}: Props<TValues>) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [values, setValues] = useState<TValues>(initialValues);
  const [stepsDisabled, setStepsDisabled] = useState(initialStepsDisabled);

  const enabledSteps = useMemo(
    () => steps.filter((s, i) => stepsDisabled.indexOf(i + 1) === -1),
    [steps, stepsDisabled],
  );
  const totalSteps = enabledSteps.length;
  const stepComponent = useMemo(() => {
    const stepIndex =
      currentStep - 1 < totalSteps ? currentStep - 1 : totalSteps - 1;
    return enabledSteps[stepIndex];
  }, [currentStep, enabledSteps, totalSteps]);

  const handleProceed = useCallback(
    (payload = {}) => {
      const oldIndex = currentStep;
      const newIndex = currentStep < totalSteps ? currentStep + 1 : currentStep;
      const newValues = { ...values, ...payload };
      unstable_batchedUpdates(() => {
        setCurrentStep(newIndex);
        setValues(newValues);
      });
      onStepChange?.(newIndex);
      if (oldIndex >= totalSteps) onSubmit?.(newValues);
    },
    [currentStep, onStepChange, onSubmit, totalSteps, values],
  );

  const handleBack = useCallback(
    (payload = {}) => {
      if (currentStep <= 1) return;
      const newIndex = currentStep - 1;
      unstable_batchedUpdates(() => {
        setCurrentStep(newIndex);
        setValues((values) => ({ ...values, ...payload }));
      });
      onStepChange?.(newIndex);
    },
    [currentStep, onStepChange],
  );

  const handleTo = useCallback(
    (step: StepType, payload = {}) => {
      if (step < 0 || step > totalSteps) return;
      unstable_batchedUpdates(() => {
        setCurrentStep(step);
        setValues((values) => ({ ...values, ...payload }));
      });
      onStepChange?.(step);
    },
    [onStepChange, totalSteps],
  );

  const handleToggleStep = useCallback((step: StepType, enabled: boolean) => {
    setStepsDisabled((current) =>
      enabled ? current.filter((s) => s !== step) : current.concat(step),
    );
  }, []);

  const handleToggleSteps = useCallback(
    (enabledSteps: StepType[], disabledSteps: StepType[]) => {
      setStepsDisabled((current) =>
        [...current, ...disabledSteps].filter((s) => !enabledSteps.includes(s)),
      );
    },
    [],
  );

  const multiStep = useMemo(
    () => ({
      values,
      currentStep: currentStep,
      totalSteps,
      proceed: handleProceed,
      back: handleBack,
      to: handleTo,
      toggleStep: handleToggleStep,
      toggleSteps: handleToggleSteps,
    }),
    [
      currentStep,
      handleBack,
      handleProceed,
      handleTo,
      handleToggleStep,
      handleToggleSteps,
      totalSteps,
      values,
    ],
  );

  return (
    <>
      {header?.(multiStep)}
      {stepComponent(multiStep)}
      {footer?.(multiStep)}
    </>
  );
};

export default MultiStep;
