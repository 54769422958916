import { ExtraErrorData } from '@sentry/integrations';
import { Breadcrumb, BreadcrumbHint } from '@sentry/react';

import { isDev, isProd } from 'utils/env.utils';

import { TENANT_CONFIGS, Tenants } from './tenants';

if (!process.env.REACT_APP_TENANT_ID)
  throw new Error('Please specify a tenant id using REACT_APP_TENANT_ID');

const tenantConfig = TENANT_CONFIGS[process.env.REACT_APP_TENANT_ID as Tenants];

const config = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: isDev()
    ? tenantConfig.API_BASE_DEVELOPMENT
    : process.env.REACT_APP_API_BASE || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',
  ANALYTICS_GOOGLE_DEBUG: process.env.REACT_APP_ANALYTICS_GOOGLE_DEBUG || '',

  // recaptcha
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',

  // error tracking
  SENTRY: {
    dsn: 'https://357bc6e9ba564c59bcd3b6ea1dd5d414@o190235.ingest.sentry.io/1401743',
    environment: process.env.REACT_APP_BUILD_ENV,
    integrations: [new ExtraErrorData()],
    ignoreErrors: [
      /ChunkLoadError/,
      'Message recieved was not valid',
      'api.errors.exception.cpi.general: api.errors.general',
      'api.errors.exception.cpi.vending_machine_offline: api.errors.general',
    ],
    sampleRate: 0.25,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
    maxBreadcrumbs: 30,
    normalizeDepth: 6,
    beforeBreadcrumb(
      breadcrumb: Breadcrumb,
      hint?: BreadcrumbHint | undefined,
    ) {
      if (hint && breadcrumb.category === 'ui.click') {
        const { target } = hint.event;
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel;
        }
      }
      return breadcrumb;
    },
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3500),
  ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS
    ? process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true'
    : true,

  ...tenantConfig,
};

export default config;
