import { v4 as uuidv4 } from 'uuid';

const CLIENT_ID_KEY = 'client_id';

const createClientId = () => {
  const clientId = uuidv4();
  localStorage.setItem(CLIENT_ID_KEY, clientId);
  return clientId;
};

const getClientId = () => {
  const clientId = localStorage.getItem(CLIENT_ID_KEY);
  return clientId ? clientId : createClientId();
};

export { getClientId };
