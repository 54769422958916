import { useCallback } from 'react';

export const useBrowserPermissions = () => {
  const query = useCallback((options: PermissionDescriptor) => {
    if ('permissions' in navigator) {
      return navigator.permissions.query(options).catch(() => {
        return Promise.resolve({ state: 'prompt' });
      });
    } else {
      // We dont have permissions so we don't know
      return Promise.resolve({ state: 'prompt' });
    }
  }, []);

  return query;
};
