import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiLinearProgress: Components['MuiLinearProgress'] = {
  defaultProps: {
    variant: 'determinate',
  },
  styleOverrides: {
    root: {
      backgroundColor: palette.neutral01[300],
      height: 2,
    },
  },
};
