import { toBase64 } from 'utils/encoding.utils';

import { ApiResponse } from 'types/api.types';
import { Pos, PosProduct, UnlockParams } from 'types/pos.types';

import RestApiService from '.';
import { getReCaptchaHeaders } from './utils';

export default {
  fetchPos(this: RestApiService, posName: Pos['name']) {
    return this.api.get<Pos>(`/pos/${posName}`);
  },
  fetchPosProducts(this: RestApiService, posName: Pos['name']) {
    return this.api.get<ApiResponse<PosProduct[]>>(
      `/pos/${toBase64(posName)}/products`,
    );
  },
  unlockPos(
    this: RestApiService,
    posName: Pos['name'],
    params: UnlockParams,
    recaptchaToken: string,
  ) {
    return this.api.post<UnlockParams, never>(
      `/pos/${posName}/unlock`,
      params,
      {
        headers: getReCaptchaHeaders(recaptchaToken),
      },
    );
  },
};
