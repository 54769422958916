import { styled } from '@mui/material/styles';

export const Step = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral01[700],
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral01[100],
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  padding: theme.spacing(4, 2, 0),
  display: 'flex',
  flexGrow: 1,
  overflowY: 'scroll',
  flexDirection: 'column',
}));

export const Actions = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.neutral01[100],
  boxShadow: '0px -2px 9px rgba(0,0,0,0.1)',
  zIndex: 1,
  padding: theme.spacing(1, 2),
}));
