import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import IcoUnsupportedDeviceWebsite from 'assets/svg/ico-unsupported-device-website.svg';

import { Content, Header, Step } from 'components/@step-flow';

const DesktopScreen = () => {
  return (
    <Step>
      <Header />
      <Content>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
          flex={1}
        >
          <img
            width="auto"
            height="180px"
            alt="Unsupported device"
            src={IcoUnsupportedDeviceWebsite}
          />

          <Typography variant="heading05" sx={{ mt: 3 }}>
            <FormattedMessage id="desktop.title.error.can_not_use_on_desktop" />
          </Typography>

          <Typography
            variant="paragraph01"
            color="neutral01.600"
            sx={{ mt: 1 }}
          >
            <FormattedMessage id="desktop.subtitle.error.can_not_use_on_desktop" />
          </Typography>
        </Box>
      </Content>
    </Step>
  );
};

export default DesktopScreen;
