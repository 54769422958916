import { CssBaseline } from '@mui/material';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';

import config from 'config';

import { isDev } from 'utils/env.utils';

import history from 'services/history';
import IntlSelectorProvider from 'services/i18n';
import { AnalyticsProvider } from 'services/mandrill';
import { NotificationProvider } from 'services/notifications';
import { queryClient } from 'services/react-query';
import RecaptchaProvider from 'services/recaptcha';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';

const App = () => (
  <RootBoundary>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />

          <IntlSelectorProvider>
            <NotificationProvider>
              <RecaptchaProvider>
                <HelmetProvider>
                  <Router history={history}>
                    <AnalyticsProvider
                      trackingId={config.ANALYTICS_GOOGLE_TRACKING_ID}
                    >
                      <Pages />
                    </AnalyticsProvider>
                  </Router>
                </HelmetProvider>
              </RecaptchaProvider>
            </NotificationProvider>
          </IntlSelectorProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>

      {isDev() && (
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      )}
    </QueryClientProvider>
  </RootBoundary>
);

export default App;
