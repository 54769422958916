import { useEffect } from 'react';

import { useBrowserPermissions } from 'hooks';

interface Props {
  onSuccess?(state: string): void;
  onError?(error: Error): void;
}

export const useCameraPermissions = ({ onSuccess, onError }: Props) => {
  const getPermission = useBrowserPermissions();

  useEffect(() => {
    // @ts-ignore
    getPermission({ name: 'camera' })
      .then(({ state }) => {
        onSuccess?.(state);
      })
      .catch((e) => {
        onError?.(e);
      });
  }, [getPermission, onError, onSuccess]);
};
