import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiToggleButton: Components['MuiToggleButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: {
      lineHeight: 1.333333,
      fontSize: '0.75rem',
      padding: '13px 16px 12px',
      borderRadius: 20,
      fontWeight: 'bold',
      color: palette.neutral01[500],
      background: palette.neutral01[300],
      border: `1px solid ${palette.neutral01[400]}`,
      '&.Mui-selected': {
        color: palette.neutral01[100],
        background: palette.neutral01[400],
        '&:hover': {
          color: palette.neutral01[100],
          background: palette.neutral01[400],
        },
      },
      '&:hover': {
        color: palette.neutral01[500],
        background: palette.neutral01[300],
      },
    },
  },
};
