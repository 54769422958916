import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      fontWeight: 'bold',
      color: palette.neutral01[700],
    },
  },
};
