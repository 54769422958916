export enum SentryTags {
  FLOW = 'FLOW',
  POS_ID = 'POS_ID',
  TEST_ID = 'TEST_ID',
  SESSION_ID = 'SESSION_ID',
  FUNCTION_NAME = 'FUNCTION_NAME',
  NEED_BOT_CHECK = 'NEED_BOT_CHECK',
  IS_TRUSTCOOLER = 'IS_TRUSTCOOLER',
  IS_EXTERNAL = 'IS_EXTERNAL',
}
