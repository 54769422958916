import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { SupportParams } from 'types/support.types';

interface Variables {
  params: SupportParams;
  recaptchaToken: string;
}

const createSupport = async (variables: Variables) => {
  const { params, recaptchaToken } = variables;
  const { data } = await apiService.createSupport(params, recaptchaToken);
  return data;
};

export const useCreateSupport = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate, isLoading } = useMutation(async (params: SupportParams) => {
    if (!executeRecaptcha)
      return Promise.reject('Could not create recaptcha token');
    const recaptchaToken = await executeRecaptcha('send_reason_request');

    const { data } = await createSupport({ params, recaptchaToken });

    return data;
  });

  return {
    createSupport: mutate,
    isLoading,
  };
};
