import { Box } from '@mui/system';
import { lazy, memo, Suspense } from 'react';

import { useDynamicImport } from 'hooks';

interface Props {
  file: string;
  width?: string;
  height?: string;
  facade?: React.ReactNode; // Render a static image of the initial start frame of the loading state so it can render while it dynamically imports
}

const LottieLazy = lazy(
  () =>
    import(
      '@novemberfiveco/lottie-react-light' /* webpackChunkName: "lottie-react-light" */
    ),
);

const Animation = ({
  file,
  width = '100%',
  height = '30vh',
  facade = null,
}: Props) => {
  const data = useDynamicImport(file);
  // Wrapped with box to avoid content shift while lottie lib is loading
  return (
    <Box sx={{ width, height }}>
      <Suspense fallback={facade}>
        {data ? (
          <LottieLazy
            animationData={data}
            renderer="svg"
            loop
            style={{ width, height }}
          />
        ) : (
          facade
        )}
      </Suspense>
    </Box>
  );
};

export default memo(Animation);
