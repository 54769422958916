import * as Sentry from '@sentry/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';
import { TestSession, TestSessionFeedback } from 'types/test-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: TestSession['id'];
  feedback: TestSessionFeedback;
}

const updateFeedback = (
  posName: Pos['name'],
  sessionId: TestSession['id'],
  feedback: TestSessionFeedback,
  recaptchaToken: string,
) => {
  return apiService.updateTestSessionFeeback(
    posName,
    sessionId,
    feedback,
    recaptchaToken,
  );
};

export const useUpdateTestSessionFeedback = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate, isLoading } = useMutation(
    async ({ posName, sessionId, feedback }: Variables) => {
      if (!executeRecaptcha) return Promise.reject('RECAPTCHA_FAILED');
      const recaptchaToken = await executeRecaptcha('tester_feedback');
      return updateFeedback(posName, sessionId, feedback, recaptchaToken);
    },
    {
      onError: (e, { posName, sessionId }) => {
        Sentry.addBreadcrumb({
          data: {
            [SentryTags.FLOW]: 'TEST',
            [SentryTags.POS_ID]: posName,
            [SentryTags.SESSION_ID]: sessionId,
            [SentryTags.FUNCTION_NAME]: 'useUpdateTestSessionFeedback',
            exception: e,
          },
          level: 'warning',
        });
      },
    },
  );

  return {
    updateTestSessionFeedback: mutate,
    isLoading,
  };
};
