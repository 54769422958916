import { Components } from '@mui/material';

import GothamBoldWoff from 'assets/fonts/Gotham-Bold.woff';
import GothamBoldWoff2 from 'assets/fonts/Gotham-Bold.woff2';
import GothamBookWoff from 'assets/fonts/Gotham-Book.woff';
import GothamBookWoff2 from 'assets/fonts/Gotham-Book.woff2';

import { font } from 'theme/branding';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'Gotham';
      src: url(${GothamBookWoff}) format('woff'), url(${GothamBookWoff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Gotham';
      src: url(${GothamBoldWoff}) format('woff'), url(${GothamBoldWoff2}) format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
    html, #root {
      height: 100%;
      width: 100%;
    }
    body {
      font-family: ${font};
      height: 100%;
      width: 100%;
      min-height: -webkit-fill-available;
    }
    .grecaptcha-badge {
      margin-bottom: 30px;
    }
  `,
};
