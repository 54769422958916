import { Components } from '@mui/material';

export const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  defaultProps: {
    notched: true,
  },
  styleOverrides: {
    notchedOutline: {
      borderWidth: 2,
      borderColor: '#B3B3B3',
    },
  },
};
