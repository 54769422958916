import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { VendSession } from 'types/vend-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: VendSession['id'];
}

const fetchSession = async (params: Variables) => {
  const { posName, sessionId } = params;
  const { data } = await apiService.fetchVendSession(posName, sessionId);
  return data;
};

export const useVendSession = (
  params: Variables,
  config: UseCommonQueryOptions<VendSession> = {},
) => {
  const { data, status, isLoading, isFetching } = useQuery(
    QueryKeys.vendSessions.byId(params.posName, params.sessionId),
    () => fetchSession(params),
    config,
  );

  return {
    data,
    status,
    isLoading,
    isFetching,
  };
};
