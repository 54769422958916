import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/locale-data/no';

import config from 'config';
import { Tenants } from 'config/tenants';

const { TENANT_ID, DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, LOCALES } = config;

const loadLocaleData = {
  [Tenants.BE]: (locale: string) =>
    ({
      en: () =>
        import(
          './translations/be-en.json' /* webpackChunkName: "locale-be-en" */
        ),
      fr: () =>
        import(
          './translations/be-fr.json' /* webpackChunkName: "locale-be-fr" */
        ),
      nl: () =>
        import(
          './translations/be-nl.json' /* webpackChunkName: "locale-be-nl" */
        ),
    }[locale]),
  [Tenants.DE]: (locale: string) =>
    ({
      en: () =>
        import(
          './translations/de-en.json' /* webpackChunkName: "locale-de-en" */
        ),
      de: () =>
        import(
          './translations/de-de.json' /* webpackChunkName: "locale-de-de" */
        ),
    }[locale]),
  [Tenants.NO]: (locale: string) =>
    ({
      en: () =>
        import(
          './translations/no-en.json' /* webpackChunkName: "locale-no-en" */
        ),
      no: () =>
        import(
          './translations/no-no.json' /* webpackChunkName: "locale-no-no" */
        ),
    }[locale]),
}[TENANT_ID];

const locales = LOCALES;

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, loadLocaleData, locales };
