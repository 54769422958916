import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { useMedia } from 'hooks';

import DesktopScreen from 'components/@errors/DesktopScreen';

interface Props {
  children: ReactNode;
}

const ApplicationContainer = ({ children }: Props) => {
  const { lg } = useMedia();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      overflow="hidden"
      bgcolor="neutral.100"
    >
      {lg ? <DesktopScreen /> : children}
    </Box>
  );
};

export default ApplicationContainer;
