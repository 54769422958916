import * as Sentry from '@sentry/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { FeedbackParams } from 'types/feedback.types';

interface Variables {
  params: FeedbackParams;
  recaptchaToken: string;
}

const createFeedback = (variables: Variables) => {
  const { params, recaptchaToken } = variables;
  return apiService.createFeedback(params, recaptchaToken);
};

export const useCreateFeedback = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate, isLoading } = useMutation(
    async (params: FeedbackParams) => {
      if (!executeRecaptcha)
        return Promise.reject('Could not create recaptcha token');
      const recaptchaToken = await executeRecaptcha('send_reason_request');

      await createFeedback({ params, recaptchaToken });
    },
    {
      onError: (e) => {
        Sentry.addBreadcrumb({
          data: {
            exception: e,
          },
          message: 'Failed to submit reason form',
          level: 'error',
        });
      },
    },
  );

  return {
    createFeedback: mutate,
    isLoading,
  };
};
