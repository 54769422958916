import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    color: palette.neutral01[700],
  },
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      lineHeight: 1.25,
    },
  },
};
