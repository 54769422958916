import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

interface Props {
  onFocus?(): void;
  onLeave?(): void;
}

export const useBrowserFocus = ({ onFocus, onLeave }: Props) => {
  useEffect(() => {
    const handleFocus = () => {
      onFocus?.();
      Sentry.addBreadcrumb({
        message: 'User has focus on the Paydrink application',
      });
    };

    window.addEventListener('focus', handleFocus, false);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    const handleBlur = () => {
      onLeave?.();

      Sentry.addBreadcrumb({
        message:
          'User no longer has focus on the Paydrink application - meaning it is closed or another tab or app was opened',
      });
    };

    window.addEventListener('blur', handleBlur, false);

    return () => {
      window.removeEventListener('blur', handleBlur);
    };
  }, [onLeave]);
};
