import { TypographyOptions } from '@mui/material/styles/createTypography';

const font = ['"Gotham"', '"Verdana"', 'sans-serif'].join(',');

const palette = {
  primary: {
    main: '#3AAB47',
  },
  secondary: {
    main: '#DF5530',
  },
  neutral01: {
    '100': '#FFFFFF',
    '300': '#F3F3F3',
    '400': '#BDBDBD',
    '500': '#737373',
    '600': '#4D4D4D',
    '700': '#000000',
  },
  accent01: {
    '100': '#CEF7D3',
    '300': '#80BA3C',
    '500': '#009639',
  },
  accent02: {
    '100': '#F40009',
    '700': '#FFD100',
  },
  accent03: {
    paydrink_green: '#3AAB47',
    fanta_orange: '#F7941E',
    cola_red: '#F40009',
  },

  alert01: '#FFE0D9',
};

const variants: TypographyOptions = {
  paragraph01: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  paragraph02: {
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  paragraph03: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  heading05: {
    fontSize: '1.125rem',
    lineHeight: '1.6875rem',
  },
  heading06: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  footer: {
    fontSize: '0.625rem',
    lineHeight: '0.5625rem',
  },
  label01: {
    fontSize: '0.857rem',
    lineHeight: '1.143rem',
  },
  stepper01: {
    fontSize: '0.6875rem',
    lineHeight: 'auto',
  },
  button01: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
};

export { font, palette, variants };
