import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { ApiResponse } from 'types/api.types';
import { Pos, PosProduct } from 'types/pos.types';

const fetchProducts = async (posName: Pos['name']) => {
  const { data } = await apiService.fetchPosProducts(posName);
  return data;
};

export const usePosProducts = (
  posName: Pos['name'],
  config: UseCommonQueryOptions<ApiResponse<PosProduct[]>> = {},
) => {
  const { data, isLoading, status } = useQuery(
    QueryKeys.pos.products(posName),
    () => fetchProducts(posName),
    config,
  );

  return {
    data: data?.data || [],
    isLoading,
    status,
  };
};
