import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import { AtomKeys } from 'store';

export const closeVendSessionOnFocusStore = createJSONStorage<boolean>(
  () => localStorage,
);

export const closeVendSessionOnFocusStorageAtom = atomWithStorage<boolean>(
  AtomKeys.CloseVendSessionOnFocus,
  true,
  closeVendSessionOnFocusStore,
);
