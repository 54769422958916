import * as Sentry from '@sentry/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { Pos, UnlockParams } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';

interface Variables {
  posName: Pos['name'];
  secret: string;
}

const unlockPos = (
  posName: Pos['name'],
  params: UnlockParams,
  recaptchaToken: string,
) => {
  return apiService.unlockPos(posName, params, recaptchaToken);
};

export const useUnlockPos = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutate, isLoading } = useMutation(
    async ({ posName, secret }: Variables) => {
      if (!executeRecaptcha) return Promise.reject('RECAPTCHA_FAILED');
      const recaptchaToken = await executeRecaptcha('unlock_request');
      return unlockPos(posName, { secret }, recaptchaToken);
    },
    {
      onError: (e, { posName }) => {
        Sentry.addBreadcrumb({
          data: {
            [SentryTags.FLOW]: 'TEST',
            [SentryTags.POS_ID]: posName,
            [SentryTags.FUNCTION_NAME]: 'useUnlockPos',
            exception: e,
          },
          level: 'warning',
        });
      },
    },
  );

  return {
    unlockPos: mutate,
    isLoading,
  };
};
