import { Link, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { locales } from 'services/i18n';
import { localeAtom } from 'store';

import { Routes } from 'pages/routes.constants';

interface Props {
  /** Makes the color of the links white */
  isLight?: boolean;
}

const Footer = ({ isLight = false }: Props) => {
  const intl = useIntl();
  const [currentLocale, setCurrentLocale] = useAtom(localeAtom);

  const filteredLocales = useMemo(
    () => locales.filter((locale) => locale !== currentLocale),
    [currentLocale],
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        py="12px"
        flexGrow={1}
      >
        <Stack spacing={2}>
          <Link
            component="button"
            textAlign="left"
            className="ot-sdk-show-settings"
          />
          <Typography
            variant="footer"
            color={isLight ? 'neutral01.100' : undefined}
          >
            {intl.formatMessage({ id: 'common.footer.company.title' })}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Link
            href={intl.formatMessage({
              id: 'common.footer.impressum.link.url',
            })}
            target="_blank"
            rel="noreferrer noopener"
            sx={{
              color: isLight ? 'neutral01.100' : undefined,
              textDecorationColor: isLight ? '#fff' : undefined,
            }}
          >
            {intl.formatMessage({ id: 'common.footer.impressum.link.label' })}
          </Link>
          <Link
            href={Routes.Privacy}
            target="_blank"
            rel="noreferrer noopener"
            sx={{
              color: isLight ? 'neutral01.100' : undefined,
              textDecorationColor: isLight ? '#fff' : undefined,
            }}
          >
            {intl.formatMessage({
              id: 'common.footer.data_protection.link.label',
            })}
          </Link>
        </Stack>
        <Stack direction="row" spacing={1}>
          {filteredLocales.map((locale) => (
            <Link
              key={locale}
              component="button"
              onClick={() => setCurrentLocale(locale)}
              sx={{
                color: isLight ? 'neutral01.100' : undefined,
                textDecorationColor: isLight ? '#fff' : undefined,
              }}
            >
              {locale.toLocaleUpperCase()}
            </Link>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
