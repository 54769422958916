import React from 'react';

import { Routes } from 'pages/routes.constants';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
const componentPromise = import(
  './MachineTestFailure' /* webpackChunkName: "machine-test-failure" */
);
const Component = React.lazy(() => componentPromise);

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Component,
  exact: true,
  path: Routes.MachineTestFailure,
  authenticated: false,
};
