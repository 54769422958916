import { Box } from '@mui/material';

import logo from 'assets/svg/img_logococa_small.png';

interface Props {
  children?: React.ReactNode;
}

const Header = ({ children }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={children ? 'space-between' : 'center'}
      m={2}
    >
      {children && (
        <Box flexGrow={1} mr={3}>
          {children}
        </Box>
      )}
      <img src={logo} alt="Coca-Cola logo" width={80} height={46} />
    </Box>
  );
};

export default Header;
