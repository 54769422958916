import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';

import { getCookie } from 'utils/cookies.utils';

import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';

interface Variables {
  posName: Pos['name'];
  pspReturnToken?: string;
}

const createSession = async (variables: Variables) => {
  const { posName, pspReturnToken } = variables;
  const { data } = await apiService.createVendSession(posName, pspReturnToken);
  return data;
};

export const useCreateVendSession = () => {
  const { mutate, isLoading } = useMutation(
    async ({ posName }: { posName: Pos['name'] }) => {
      const pspReturnToken = getCookie('PspReturnToken');

      return createSession({ posName, pspReturnToken });
    },
    {
      onError: (e, { posName }) => {
        // Log to sentry why we couldn't make the session
        Sentry.addBreadcrumb({
          data: {
            [SentryTags.FLOW]: 'CREATE SESSION',
            [SentryTags.POS_ID]: posName,
            [SentryTags.FUNCTION_NAME]: 'useCreateVendSession',
            exception: e,
          },
          level: 'warning',
        });
      },
    },
  );

  return {
    createSession: mutate,
    isLoading,
  };
};
