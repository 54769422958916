import * as React from 'react';

function SvgIcoWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
        fill="#F32C00"
      />
    </svg>
  );
}

const MemoSvgIcoWarning = React.memo(SvgIcoWarning);
export default MemoSvgIcoWarning;
