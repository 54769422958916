import {
  Box,
  Button,
  Drawer,
  Link,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCreateFeedback } from 'queries';
import { FeedbackContext, FeedbackReason } from 'types/feedback.types';
import { VendSession } from 'types/vend-session.types';

import { useFeedbackReasonsByContext } from './hooks';

interface Props {
  open: boolean;
  context: FeedbackContext;
  sessionId: VendSession['id'];
  onClose(): void;
}

interface FormData {
  reasonId?: FeedbackReason['id'];
  remarks: string;
}

const OTHER_REASON = -1;

const FeedBack = ({ open, context, sessionId, onClose }: Props) => {
  const intl = useIntl();
  const { createFeedback } = useCreateFeedback();
  const { topReasons, isLoading } = useFeedbackReasonsByContext(context, {
    enabled: open,
  });

  const { handleSubmit, control, watch } = useForm<FormData>();
  const selectedReason = watch('reasonId');

  const handleSubmitFeedback = (data: FormData) => {
    createFeedback(
      {
        sessionId,
        context,
        remarks: data.reasonId === OTHER_REASON ? data.remarks : undefined,
        reasonId: data.reasonId === OTHER_REASON ? undefined : data.reasonId,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Drawer keepMounted={false} anchor="bottom" open={open} onClose={onClose}>
      <Stack alignItems="stretch">
        <Typography variant="heading05" fontWeight="bold" textAlign="center">
          <FormattedMessage id="submit_error_flow.modal.title" />
        </Typography>

        {isLoading ? (
          <Stack mt={5} spacing={2}>
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
          </Stack>
        ) : (
          <Controller
            name="reasonId"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ToggleButtonGroup
                orientation="vertical"
                value={value}
                exclusive
                fullWidth
                onChange={(_e, value) => onChange(value)}
                sx={{ mt: 5 }}
                ref={ref}
              >
                {topReasons.map((reason) => (
                  <ToggleButton key={reason.id} value={reason.id}>
                    {reason.title}
                  </ToggleButton>
                ))}
                <ToggleButton value={OTHER_REASON}>
                  <FormattedMessage id="submit_error_flow.modal.reasons.other.label" />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        )}

        {selectedReason === OTHER_REASON && (
          <Controller
            name="remarks"
            control={control}
            rules={{
              required: intl.formatMessage({
                id: 'submit_error_flow.modal.remarks.error.required',
              }),
            }}
            render={({
              field: { name, onChange, onBlur, value, ref },
              fieldState: { error },
            }) => (
              <TextField
                sx={{ mt: 3 }}
                multiline
                rows={4}
                required
                name={name}
                label={intl.formatMessage({
                  id: 'submit_error_flow.modal.remarks.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'submit_error_flow.modal.remarks.placeholder',
                })}
                fullWidth
                autoFocus
                error={!!error}
                helperText={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
              />
            )}
          />
        )}

        <Box
          display="flex"
          alignItems="center"
          minHeight="40px"
          justifyContent={selectedReason ? 'space-between' : 'center'}
          mt={3}
        >
          <Link component="button" onClick={onClose}>
            <FormattedMessage id="submit_error_flow.modal.skip.label" />
          </Link>
          {selectedReason && (
            <Button
              aria-label="submit feedback"
              onClick={handleSubmit(handleSubmitFeedback)}
            >
              <FormattedMessage id="submit_error_flow.modal.submit.label" />
            </Button>
          )}
        </Box>
      </Stack>
    </Drawer>
  );
};

export default FeedBack;
