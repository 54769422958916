import { useMutation } from 'react-query';

import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { VendSession } from 'types/vend-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: VendSession['id'];
}

const closeSession = (variables: Variables) => {
  const { posName, sessionId } = variables;
  return apiService.closeVendSession(posName, sessionId);
};

export const useCloseVendSession = () => {
  const { mutate } = useMutation(closeSession);

  return {
    closeSession: mutate,
  };
};
