import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { localeAtom } from 'store';

import { loadLocaleData } from './config';

const IntlSelectorProvider: FC = ({ children }) => {
  const [locale] = useAtom(localeAtom);
  const [messages, setMessages] = useState<Record<string, string>>();

  useEffect(() => {
    async function loadLocaleMessages(locale: string) {
      const messages = await loadLocaleData(locale)?.();
      // Merge in en since that contains everything
      const defaultMessages = await loadLocaleData('en')?.();
      setMessages({ ...defaultMessages?.default, ...messages?.default });
    }
    loadLocaleMessages(locale);
  }, [locale]);

  // Ensure we don't render the app if there are no messages yet
  if (!messages) return null;

  return (
    <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
