import { VendSessionStatus } from './vend-session.types';
// For the sake of not importing from vend-session and keeping it seperated
export { VendSessionStatus as TestSessionStatus };

export enum TestErrorContext {
  GENERAL = 'GENERAL',
  TIMEOUT = 'TIMEOUT',
  MACHINE_OFFLINE = 'MACHINE_OFFLINE',
  MACHINE_ERROR = 'MACHINE_ERROR',
  SESSION_ERROR = 'SESSION_ERROR',
  TRUST_COOLER_NOT_ALLOWED = 'TRUST_COOLER_NOT_ALLOWED',
}

export enum TestSessionFeedback {
  NONE = 'NONE',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type TestSession = {
  id: string;
  status: VendSessionStatus;
  startTime: string;
  endTime?: string;
  amount?: number;
  timeout: number;
  testFeedback: TestSessionFeedback;
};

export type TestSessionParams = {
  testId: string;
  code: string;
};
