import 'utils/polyfill.utils';

import * as Sentry from '@sentry/browser';
import QrScanner from 'qr-scanner';
import ReactDOM from 'react-dom';

import config from 'config';

// eslint-disable-next-line import/no-webpack-loader-syntax
import QrScannerWorkerPath from '!!file-loader!../node_modules/qr-scanner/qr-scanner-worker.min.js';

import { runInDev, runInProd } from 'utils/env.utils';

import App from './App';

QrScanner.WORKER_PATH = QrScannerWorkerPath;

runInProd(() => Sentry.init(config.SENTRY));

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

ReactDOM.render(<App />, document.getElementById('root'));
