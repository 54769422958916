import { AxiosResponse } from 'axios';

import { ApiResponse } from 'types/api.types';
import { Support, SupportParams } from 'types/support.types';

import RestApiService from '.';
import { getReCaptchaHeaders } from './utils';

export default {
  createSupport(
    this: RestApiService,
    params: SupportParams,
    recaptchaToken: string,
  ) {
    return this.api.post<SupportParams, AxiosResponse<ApiResponse<Support>>>(
      '/support',
      params,
      {
        headers: getReCaptchaHeaders(recaptchaToken),
      },
    );
  },
};
