import { useMediaQuery, useTheme } from '@mui/material';

export const useMedia = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'), {
    noSsr: true,
  });
  const lg = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  return {
    xs,
    sm,
    md,
    lg,
  };
};
