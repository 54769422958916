import config from 'config';

const getBrowserLocale = () => {
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (browserLocales[0]) {
    return browserLocales[0].trim().split(/-|_/)[0];
  }
};

// Returns the browser locale or tenant default
export const getDefaultLocale = () => {
  const browserLocale = getBrowserLocale();
  return browserLocale && config.LOCALES.includes(browserLocale)
    ? browserLocale
    : config.DEFAULT_LOCALE;
};
