import * as Sentry from '@sentry/react';
import { AxiosError, AxiosInstance } from 'axios';

import { isDev } from 'utils/env.utils';

const getBetterErrorByResponse = (err: AxiosError) => {
  if (err.code === 'ECONNABORTED') return err.message;

  const res = err.response;

  if (res) {
    const apiErrors = res.data.errors;

    if (apiErrors && apiErrors.length > 0) {
      const { detail, title } = apiErrors[0];
      return `${title}: ${detail}`;
    }
    return isDev() ? `${res.status}: ${res.statusText}` : err.message;
  }

  return 'Unmapped errorByResponse';
};

export const createErrorResponseInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      error.message = getBetterErrorByResponse(error);

      Sentry.addBreadcrumb({
        message: 'API response error',
        data: {
          error,
        },
      });

      return Promise.reject(error);
    },
  );
};
