import * as React from 'react';

function SvgIcoMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} />
      <rect x={6} y={10.714} width={12} height={2.571} rx={1.286} fill="#fff" />
    </svg>
  );
}

const MemoSvgIcoMinus = React.memo(SvgIcoMinus);
export default MemoSvgIcoMinus;
