import { serviceManager } from '@novemberfiveco/mandrill';
import GoogleAnalyticsService from '@novemberfiveco/mandrill-service-google-analytics';
import { useAtom } from 'jotai';
import { ReactChild, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router';

import config from 'config';

import { isTest } from 'utils/env.utils';

import { localeAtom } from 'store';

import * as collection from './collection1.mandrill';
import { googleanalytics1 } from './config.mandrill';
import { loadAnalytics, reloadOTBanner } from './utils';

interface Props {
  trackingId: string;
  children: ReactChild;
}

const eventsToTrack = [collection.trackEventVisitorAccess];

const AnalyticsProvider = ({ trackingId, children }: Props) => {
  const [currentLocale] = useAtom(localeAtom);
  const history = useHistory();

  history.listen(() => {
    reloadOTBanner();
  });

  const startTrackEvents = useCallback(() => {
    eventsToTrack.forEach((event) => event());
  }, []);

  const enableAnalytics = useCallback(() => {
    // @ts-ignore
    window.ga('create', trackingId, 'auto');

    // Create google analytics service
    const googleAnalyticsService = new GoogleAnalyticsService(
      googleanalytics1.id,
      trackingId,
    );
    serviceManager.registerService(googleAnalyticsService);
    startTrackEvents();
  }, [startTrackEvents, trackingId]);

  useEffect(() => {
    // @ts-ignore
    if (!window.ga && !isTest()) {
      loadAnalytics({ debug: config.ANALYTICS_GOOGLE_DEBUG });
    }

    // @ts-ignore
    if (window.ga) {
      enableAnalytics();
    }
  }, [enableAnalytics]);

  return (
    <>
      <Helmet>
        <script
          className="onetrust-consent-sdk"
          src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js?l=${currentLocale}`}
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={config.COOKIES_BANNER_DOMAIN_SCRIPT_ID}
          data-document-language="true"
        />
        {/* <script
          type="text/javascript"
          src={`https://cdn.cookielaw.org/consent/${config.COOKIES_BANNER_DOMAIN_SCRIPT_ID}/OtAutoBlock.js`}
        /> */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
      </Helmet>

      {children}
    </>
  );
};

export default AnalyticsProvider;
