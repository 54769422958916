import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';

import { useValidateVendSession } from 'hooks';
import { useVendSession } from 'queries';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';
import { VendSession } from 'types/vend-session.types';

interface Props {
  posName: Pos['name'];
  sessionId: VendSession['id'];
  onChange?(session: VendSession): void;
}

export const usePollVendSession = ({ posName, sessionId, onChange }: Props) => {
  const validateSession = useValidateVendSession(posName);
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(
    1000,
  );

  const handleSuccess = useCallback(
    (session: VendSession) => {
      validateSession(session);
      onChange?.(session);
    },
    [onChange, validateSession],
  );

  const { data } = useVendSession(
    { posName: posName, sessionId: sessionId },
    {
      enabled: !!sessionId,
      refetchInterval,
      onSuccess: handleSuccess,
      onError: (error) => {
        // ignore error if it's due to timeout as it gives a lot of false positives (users returning to an old session tab)
        if (error.code !== 'ECONNABORTED') {
          Sentry.addBreadcrumb({
            data: {
              [SentryTags.FLOW]: 'PAYMENT',
              [SentryTags.POS_ID]: posName,
              [SentryTags.SESSION_ID]: sessionId,
              [SentryTags.FUNCTION_NAME]: 'usePollVendSession',
              exception: error,
            },
            level: 'warning',
          });
        }
      },
    },
  );

  const handleStopPolling = useCallback(
    () => setRefetchInterval(undefined),
    [],
  );

  return {
    vendSession: data,
    stopPolling: handleStopPolling,
  };
};
