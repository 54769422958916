/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { isDev } from 'utils/env.utils';

import DevLoadSessionGateway from './dev-load-session-gateway';
import DevPaymentGateway from './dev-payment-gateway';
import ErrorDetail from './error-detail';
import FormSuccess from './form-success';
import Home from './home';
import LoadSession from './load-session';
import MachineTest from './machine-test';
import MachineTestFailure from './machine-test-failure';
import MachineTestSuccess from './machine-test-success';
import PaymentResult from './payment-result';
import Privacy from './privacy';
import Refund from './refund';
import RequestRefund from './request-refund';
import { Route } from './routes.types';
import UnlockMachine from './unlock-machine';

export default [
  Home,
  LoadSession,
  PaymentResult,
  ErrorDetail,
  FormSuccess,
  UnlockMachine,
  MachineTest,
  MachineTestSuccess,
  MachineTestFailure,
  RequestRefund,
  Refund,
  Privacy,
  ...(isDev() ? [DevPaymentGateway, DevLoadSessionGateway] : []),
] as Route[];
