import { ClickAwayListener, Tooltip as MuiTooltip } from '@mui/material';
import { cloneElement, ReactElement, useCallback, useState } from 'react';

interface Props {
  title: string;
  arrow?: boolean;
  children: ReactElement;
}

const Tooltip = ({ title, arrow = true, children }: Props) => {
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => setOpen((existing) => !existing), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <MuiTooltip
        title={title}
        arrow={arrow}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        onClose={handleClose}
      >
        {cloneElement(children, {
          onClick: handleToggle,
        })}
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
