export enum ErrorContext {
  GENERAL = 'GENERAL',
  TIMEOUT = 'TIMEOUT',
  INVALID_QR = 'INVALID_QR',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  MACHINE_ERROR = 'MACHINE_ERROR',
  MACHINE_OFFLINE = 'MACHINE_OFFLINE',
  MACHINE_DELIVERY = 'MACHINE_DELIVERY',
  SESSION_IN_PROGRESS = 'SESSION_IN_PROGRESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  SUPPORT = 'SUPPORT',
  PURCHASE_ERROR = 'PURCHASE_ERROR',
  CANCELLED = 'CANCELLED',
  AUTOMATIC_REFUND = 'AUTOMATIC_REFUND',
  NO_PAYMENT_METHOD_CONFIGURED = 'NO_PAYMENT_METHOD_CONFIGURED',
}
