import { useAtom } from 'jotai';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch } from 'react-router-dom';

import config from 'config';

import { localeAtom } from 'store';

import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { RouteProtected, RoutePublic } from 'components/@common/Route';
import ApplicationContainer from 'components/@screen/ApplicationContainer';
import LoadingState from 'components/@states/LoadingState';

import routes from './routes';

const NotFound = React.lazy(
  () => import('./not-found' /* webpackChunkName: "not-found" */),
);

const Pages = () => {
  const [currentLocale] = useAtom(localeAtom);

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLocale }}>
        <title>{config.TITLE}</title>
        <meta name="description" content={config.DESCRIPTION} />
      </Helmet>

      <RouteBoundary>
        <ApplicationContainer>
          <Suspense fallback={<LoadingState />}>
            <Switch>
              {routes.map((route) => {
                const routeProps = {
                  key: route.path?.toString(),
                  exact: route.exact,
                  path: route.path,
                  component: route.component,
                  authenticated: route.authenticated,
                };

                return route.authenticated ? (
                  <RouteProtected {...routeProps} />
                ) : (
                  <RoutePublic {...routeProps} />
                );
              })}
              <RoutePublic component={NotFound} />
            </Switch>
          </Suspense>
        </ApplicationContainer>
      </RouteBoundary>
    </>
  );
};

export default Pages;
