import axios, { AxiosRequestConfig } from 'axios';

import config from 'config';

import interceptors from './interceptors';
import { transformRequest, transformResponse } from './transformers';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL: config.API_BASE,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  timeout: 30000,
  transformRequest: [transformRequest],
  transformResponse: [transformResponse],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });
  // Interceptors
  interceptors.forEach((interceptor) => interceptor(manager));
  return manager;
};

export { createManager };
