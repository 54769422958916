// @flow

/*
 *
 * Mandrill collection
 * Call these methods to track to registered services
 *
 */

import { serviceManager } from '@novemberfiveco/mandrill';

/*
 *
 * Parameter types
 *
 */

// Type definition for: automatic_refund_status

export type EventAutomaticRefundSumbitAutomaticRefundStatus =
  | 'SUCCESSFUL'
  | 'UNSUCCESSFUL';

// Type definition for: support_status

export type EventSupportSubmitSupportStatus = 'SUCCESSFUL' | 'UNSUCCESSFUL';

// Type definition for: purchase_success

export type EventVisitorQrscanThirdpartyPurchaseSuccess =
  | 'SUCCESSFULL'
  | 'UNSUCCESSFULL';

// Type definition for: purchase_success

export type EventVisitorQrscanWebsitePurchaseSuccess =
  | 'SUCCESSFULL'
  | 'UNSUCCESSFULL';

/*
 *
 * Events tracking functions
 *
 */

/*
 * Track: EventAutomaticRefund
 * Description: Track number of times an error was detected and automatic refund flow was started
 */

export const trackEventAutomaticRefund = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_automatic_refund',
    {
      category: `Refund`,
      action: `Start automatic refund process`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventAutomaticRefundSumbit
 */

export const trackEventAutomaticRefundSumbit = (
  automaticRefundStatus?: EventAutomaticRefundSumbitAutomaticRefundStatus,
) => {
  // Format parameters

  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_automatic_refund_sumbit',
    {
      category: `Refund`,
      action: `Submit automatic refund`,
      label: automaticRefundStatus,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventPaymentDeclided
 * Description: Track why a payment may be cancelled
 */

export const trackEventPaymentDeclided = (reasonPaymentDeclined?: string) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_payment_declined',
    {
      category: `Payment`,
      action: `Payment declined`,
      label: reasonPaymentDeclined ?? '',
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventSupport
 * Description: Track number of times customer started a support flow manually
 */

export const trackEventSupport = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_support',
    {
      category: `Support`,
      action: `Start support flow`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventSupportSubmit
 */

export const trackEventSupportSubmit = (
  supportStatus?: EventSupportSubmitSupportStatus,
) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_support_submit',
    {
      category: `Support`,
      action: `Support flow submit`,
      label: supportStatus,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventTimeout
 * Description: Track number of times the timeout countdown was reached before customers chose a drink
 */

export const trackEventTimeout = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_timeout',
    {
      category: `Timeout`,
      action: `Timeout before choosing a drink`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventVisitorAccess
 * Description: Track the number of people entering the mobile payment website directly without further action
 */

export const trackEventVisitorAccess = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_visitor_access',
    {
      category: `Visitor`,
      action: `Direct access site`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventVisitorQrscanThirdparty
 * Description: Track number of people that scanned with 3rd party app and made a purchase
 */

export const trackEventVisitorQrscanThirdparty = (
  purchaseSuccess?: EventVisitorQrscanThirdpartyPurchaseSuccess,
) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_visitor_qrscan_thirdparty',
    {
      category: `Visitor`,
      action: `QR code scan thirdparty `,
      label: purchaseSuccess,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: EventVisitorQrscanWebsite
 * Description: Track number of people that scanned with the website, and made a purchase
 */

export const trackEventVisitorQrscanWebsite = (
  purchaseSuccess?: EventVisitorQrscanWebsitePurchaseSuccess,
) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'event_visitor_qrscan_website',
    {
      category: `Visitor`,
      action: `QR code website scan`,
      label: purchaseSuccess,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export default null;
