import { useAtom } from 'jotai';
import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { localeAtom } from 'store';
import { ApiResponse } from 'types/api.types';
import { FeedbackReason } from 'types/feedback.types';

const fetchReasons = async () => {
  const { data } = await apiService.fetchFeedbackReasons();
  return data;
};

export const useFeedbackReasons = (
  config: UseCommonQueryOptions<ApiResponse<FeedbackReason[]>> = {},
) => {
  const [locale] = useAtom(localeAtom);

  const { data, isLoading, status } = useQuery(
    QueryKeys.feedback.reasons(locale),
    () => fetchReasons(),
    {
      staleTime: StaleTime.FOREVER,
      ...config,
    },
  );

  return {
    data: data?.data || [],
    isLoading,
    status,
  };
};
