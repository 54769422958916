import * as React from 'react';

function SvgIcoPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.286 7.286a1.286 1.286 0 10-2.572 0v3.428H7.286a1.286 1.286 0 000 2.572h3.428v3.428a1.286 1.286 0 102.572 0v-3.428h3.428a1.286 1.286 0 000-2.572h-3.428V7.286z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoSvgIcoPlus = React.memo(SvgIcoPlus);
export default MemoSvgIcoPlus;
