import { PaymentMethod } from './pos.types';

export enum VendSessionStatus {
  PENDING = 'PENDING',
  PAYMENT_INIT = 'PAYMENT_INIT',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  FAILED_TIMEOUT = 'FAILED_TIMEOUT',
  FAILED_MACHINE_ERROR = 'FAILED_MACHINE_ERROR',
  PAYMENT_CREATION_FAILED = 'PAYMENT_CREATION_FAILED',
  PAYMENT_METHOD_SELECTION = 'PAYMENT_METHOD_SELECTION',
  FAILED_MACHINE_NO_SALE = 'FAILED_MACHINE_NO_SALE',
  FAILED_SESSION_IN_PROGRESS = 'FAILED_SESSION_IN_PROGRESS',
  FAILED_VENDING_MACHINE_OFFLINE = 'FAILED_VENDING_MACHINE_OFFLINE',
  CANCELLED = 'CANCELLED',
}

export enum PaymentActions {
  REDIRECT = 'REDIRECT',
  POST_REDIRECT = 'POST_REDIRECT',
}

export enum ProductVendStatus {
  VEND_PENDING = 'VEND_PENDING',
  VEND_IN_PROGRESS = 'VEND_IN_PROGRESS',
  VEND_SUCCESSFUL = 'VEND_SUCCESSFUL',
  VEND_FAILED = 'VEND_FAILED',
}

export enum SelectionMethod {
  ON_MOBILE = 'ON_MOBILE',
  ON_MACHINE = 'ON_MACHINE',
}

export type VendingProduct = {
  id: string;
  status: ProductVendStatus;
  price: {
    value: number;
    currency: string;
  };
  title: string;
  volume: string;
};

export type VendSession = {
  id: string;
  status: VendSessionStatus;
  startTime: string;
  endTime?: string;
  amount?: number;
  paymentInfo?: {
    action: PaymentActions;
    parameters?: object;
    paymentMethod?: PaymentMethod;
    url: string;
  };
  lockTimeout?: number;
  // TODO: No currency and this is in cents?
  productPrice?: number;
  // JSON Representation
  productsVended?: string;
  timeout: number;
  refunded: boolean;
  supportedPaymentMethods?: PaymentMethod[];
  productStatus: VendingProduct[];
  productSelectionMethod: SelectionMethod;
};

export type VendSessionProduct = {
  amount: number;
  productId: number;
};
