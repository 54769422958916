import { useEffect } from 'react';

const isSupported = 'vibrate' in navigator;

export const useVibration = ({
  enabled,
  sequence = [700, 50, 200, 50, 200],
}: {
  enabled: boolean;
  sequence?: number[];
}) => {
  useEffect(() => {
    if (isSupported && enabled) {
      window.navigator.vibrate(sequence);
    }
  }, [enabled, sequence]);
};
