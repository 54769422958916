import { Components } from '@mui/material';

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: {
      '& label, & label.Mui-focused': {
        fontWeight: 700,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderWidth: 2,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
      },
    },
  },
};
