import { VendSession } from './vend-session.types';

export enum FeedbackContext {
  SCAN = 'SCAN',
  INVALID_QR = 'INVALID_QR',
  SELECTION = 'SELECTION',
  PAYMENT_INSTRUCTION = 'PAYMENT_INSTRUCTION',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  TIMEOUT = 'TIMEOUT',
  SUPPORT = 'SUPPORT',
  CONFIRMATION = 'CONFIRMATION',
  NO_PAYMENT_METHOD_CONFIGURED = 'NO_PAYMENT_METHOD_CONFIGURED',
}

export type FeedbackReason = {
  id: number;
  title: string;
  applicableSteps: FeedbackContext[];
};

export type FeedbackParams = {
  reasonId?: FeedbackReason['id'];
  remarks?: string;
  context: FeedbackContext;
  sessionId: VendSession['id'];
};
