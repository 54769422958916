import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Pos } from 'types/pos.types';

async function fetchPos(posName: Pos['name']) {
  const { data } = await apiService.fetchPos(posName);

  return data;
}

export const usePos = (
  posName: Pos['name'],
  config?: UseCommonQueryOptions<Pos>,
) => {
  const { data, isLoading } = useQuery(
    QueryKeys.pos.byName(posName),
    () => fetchPos(posName),
    {
      staleTime: StaleTime.FIFTEEN_MIN,
      ...config,
    },
  );

  return {
    data,
    isLoading,
  };
};
