import { keyframes, styled } from '@mui/material/styles';

const fadeInOut = keyframes`
from { opacity: 0; }
50% { opacity: 1; }
to { opacity: 0; }
`;

const OverlayAnimation = styled('div')(({ theme }) => ({
  pointerEvents: 'none',
  position: 'fixed',
  top: '0px',
  right: '0px',
  left: '0px',
  bottom: '0px',
  opacity: 0,
  backgroundColor: theme.palette.secondary['main'],
  zIndex: 999,
  animation: `${fadeInOut} 0.3s ease-in-out`,
}));

export default OverlayAnimation;
