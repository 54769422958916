import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { DEFAULT_AUTO_HIDE } from './constants';
import { Notification } from './types';

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const add = useCallback(
    (notification: Notification) => {
      enqueueSnackbar(notification.message, {
        variant: notification.type,
        autoHideDuration: DEFAULT_AUTO_HIDE,
        disableWindowBlurListener: true,
      });
    },
    [enqueueSnackbar],
  );

  const success = useCallback(
    (message: string) => add({ message, type: 'success' }),
    [add],
  );

  const error = useCallback(
    (message: string) => add({ message, type: 'error' }),
    [add],
  );

  return {
    add,
    error,
    success,
  };
};

export default useNotifications;
