import { Components } from '@mui/material';

import { palette } from 'theme/branding';

export const MuiChip: Components['MuiChip'] = {
  variants: [
    {
      props: { variant: 'button' },
      style: {
        fontSize: '0.75rem',
        height: '22px',
        '& .MuiChip-label': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    {
      props: { variant: 'button', color: 'primary' },
      style: {
        backgroundColor: palette.accent01['500'],
      },
    },
    {
      props: { variant: 'button', disabled: true },
      style: {
        backgroundColor: palette.neutral01['500'],
      },
    },
  ],
};
