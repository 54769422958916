import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

/*
  provide the import promise statement as a parameter:
    useDynamicImport<Type>(import('path/to/some/file.extension')
  return type of the promise could be better.
 */
export const useDynamicImport = <T extends object>(file: string) => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const getImport = async () => {
      try {
        // Remember that the import is relative
        const data = await import(
          `../assets/animations/${file}` /* webpackChunkName: "lottie-react-[request]" */
        );
        setData(cloneDeep(data.default));
      } catch (err) {
        setData(null);
        console.error('Dynamic import failed', err);
      }
    };
    getImport();
  }, [file]);

  return data;
};
