import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import config from 'config';

import { localeAtom } from 'store';

interface Props {
  children: ReactNode;
}

const RecaptchaProvider = ({ children }: Props) => {
  const [locale] = useAtom(localeAtom);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.RECAPTCHA_SITE_KEY}
      language={locale}
      useRecaptchaNet
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
