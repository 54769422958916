import { Components } from '@mui/material';

import { font } from 'theme/branding';

export const MuiTypography: Components['MuiTypography'] = {
  defaultProps: {
    fontFamily: font,
    variantMapping: {
      heading05: 'h5',
      heading06: 'h6',
      paragraph01: 'p',
      paragraph02: 'p',
      paragraph03: 'p',
      footer: 'p',
      button01: 'span',
      stepper01: 'p',
      label01: 'label',
    },
  },
};
