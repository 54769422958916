export enum Routes {
  Root = '/',
  Error = '/error',
  Refund = '/refund',
  RequestRefund = '/request-refund',

  // Displays success message when submitting the form
  FormSuccess = '/form-success',

  // Callback url after we redirect back from payment provider
  LoadSession = '/load-session/:posName/:sessionId',

  // Displays the result after payment
  PaymentResult = '/payment-result',

  // Machine Test flow routes
  MachineTest = '/machine-test-flow',
  MachineTestFailure = '/machine-test-flow-failure',
  MachineTestSuccess = '/machine-test-flow-success',

  // Machine Unlock
  UnlockMachine = '/unlock-machine/:posName',

  // generic routes
  RedirectNotAuthorized = '/login',
  Privacy = '/privacy',

  // DEV ONLY PAYMENT PAGE
  DevPaymentGateway = '/dev/payment-gateway/:posName/:sessionId',

  // DEV ONLY LOAD SESSION PAGE
  DevLoadSessionGateway = '/dev/load-session/:posName/:sessionId',
}
