import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorContext } from 'types/error.types';
import { Pos } from 'types/pos.types';
import { VendSession, VendSessionStatus } from 'types/vend-session.types';

import { Routes } from 'pages/routes.constants';

const ErrorContextMapping: { [key: string]: ErrorContext } = {
  [VendSessionStatus.FAILED_TIMEOUT]: ErrorContext.TIMEOUT,
  [VendSessionStatus.FAILED_VENDING_MACHINE_OFFLINE]:
    ErrorContext.MACHINE_OFFLINE,
  [VendSessionStatus.FAILED_MACHINE_ERROR]: ErrorContext.MACHINE_ERROR,
  [VendSessionStatus.FAILED_SESSION_IN_PROGRESS]:
    ErrorContext.SESSION_IN_PROGRESS,
  [VendSessionStatus.CANCELLED]: ErrorContext.CANCELLED,
  [VendSessionStatus.PAYMENT_FAILED]: ErrorContext.PAYMENT_FAILED,
  [VendSessionStatus.PAYMENT_CREATION_FAILED]: ErrorContext.PAYMENT_FAILED,
  [VendSessionStatus.FAILED]: ErrorContext.GENERAL,
};

export const useValidateVendSession = (posName: Pos['name']) => {
  const history = useHistory();

  const validate = useCallback(
    (session?: VendSession) => {
      if (!session) return;

      // When the machine refunded the user let them know
      if (session.refunded) {
        history.push(Routes.Refund, {
          session,
          posName,
        });
        return;
      }

      const context = ErrorContextMapping[session.status];
      if (context) {
        history.push(Routes.Error, {
          context,
          sessionId: session?.id,
          posName,
        });
      }
    },
    [history, posName],
  );

  return validate;
};
