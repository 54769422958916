import { ApiResponse } from 'types/api.types';
import { FeedbackParams, FeedbackReason } from 'types/feedback.types';

import RestApiService from '.';
import { getReCaptchaHeaders } from './utils';

export default {
  fetchFeedbackReasons(this: RestApiService) {
    return this.api.get<ApiResponse<FeedbackReason[]>>('/feedback/reasons');
  },
  createFeedback(
    this: RestApiService,
    params: FeedbackParams,
    recaptchaToken: string,
  ) {
    return this.api.post<FeedbackParams, never>('/feedback', params, {
      headers: getReCaptchaHeaders(recaptchaToken),
    });
  },
};
