import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';

import { apiService } from 'services';
import { Pos } from 'types/pos.types';
import { SentryTags } from 'types/sentry.types';
import { VendSession, VendSessionProduct } from 'types/vend-session.types';

interface Variables {
  posName: Pos['name'];
  sessionId: VendSession['id'];
  params: VendSessionProduct[];
}

const createProducts = async (variables: Variables) => {
  const { posName, sessionId, params } = variables;
  const { data } = await apiService.createVendSessionProducts(
    posName,
    sessionId,
    params,
  );
  return data;
};

export const useCreateVendSessionProducts = () => {
  const { mutate, isLoading } = useMutation(createProducts, {
    onError: (e, { posName, sessionId }) => {
      Sentry.addBreadcrumb({
        data: {
          [SentryTags.FLOW]: 'PAYMENT',
          [SentryTags.POS_ID]: posName,
          [SentryTags.SESSION_ID]: sessionId,
          [SentryTags.FUNCTION_NAME]: 'useCreateVendSessionProducts',
          exception: e,
        },
        level: 'warning',
      });
    },
  });

  return {
    createProducts: mutate,
    isLoading,
  };
};
